* {
  margin: 0;
  padding: 0;
  /* font-family: 'Courier New', Courier, monospace; */
  font-family: Aleo;
}

/* Element Classes */
h2 {
  color: navy;
  margin-top: 10px;
}




/* Custom Classes */
.box {
  /* border: 1px solid black; */
  border-radius: 15px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  min-height: 250px;
  width: 50%;
}

.footer {
  text-align: right;
  /* background-color: #649ccb; */
  color: #649ccb;
  padding: 20px;
}

.footer p {
  font-size: 12px;
}

.it {
  color: red;
}

.message {
  text-align: left;
}

.navbarLogo {
  /* font-family: Aleo; */
  /* width: 25%; */
}

.title {
  color: #649ccb;
}

.welcome {
  font-size: 60px;
  text-align: center;
  color: #eaf7fd;
  background-color: #649ccb;
}

.welcomeLeft {
  font-family: 'Montserrat', sans-serif;;
  text-align: left;
}

.welcomeRight {
  font-family: 'Montserrat', sans-serif;
  text-align: right;
}





@media screen and (max-width: 760px) {
  .welcome {
    font-size: 24px;
  }

  .products {
    padding: 10px;
  }

  .box {
    margin: 50px 0;
    width: 90%;
  }

  .navbarLogo {
    width: 100%;
  }
}